import {getOneExperimentConfig} from "@/api/erp/OfficialWebExperiment";


/**
 官网产品model
 */
class OfficialWebExperimentModel {
    // 获取一个
    static async getOneExperimentConfig(id) {
        let [res] = await getOneExperimentConfig({
            id: id,
            configType: "test"
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }
}

export {OfficialWebExperimentModel}
